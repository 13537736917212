import React from "react";
import { css } from "@emotion/react";

// job
import Pld from '../images/icons/job/pld.inline.svg';
import War from '../images/icons/job/war.inline.svg';
import Drk from '../images/icons/job/drk.inline.svg';
import Gnb from '../images/icons/job/gnb.inline.svg';

import Whm from '../images/icons/job/whm.inline.svg';
import Sch from '../images/icons/job/sch.inline.svg';
import Ast from '../images/icons/job/ast.inline.svg';
import Sge from '../images/icons/job/sge.inline.svg';

import Mnk from '../images/icons/job/mnk.inline.svg';
import Drg from '../images/icons/job/drg.inline.svg';
import Nin from '../images/icons/job/nin.inline.svg';
import Sam from '../images/icons/job/sam.inline.svg';
import Rpr from '../images/icons/job/rpr.inline.svg';

import Brd from '../images/icons/job/brd.inline.svg';
import Mch from '../images/icons/job/mch.inline.svg';
import Dnc from '../images/icons/job/dnc.inline.svg';

import Blm from '../images/icons/job/blm.inline.svg';
import Smn from '../images/icons/job/smn.inline.svg';
import Rdm from '../images/icons/job/rdm.inline.svg';
import Blu from '../images/icons/job/blu.inline.svg';

// clas
import Gla from '../images/icons/class/gla.inline.svg';
import Mrd from '../images/icons/class/mrd.inline.svg';

import Cnj from '../images/icons/class/cnj.inline.svg';
import Acn from '../images/icons/class/acn.inline.svg';

import Pgl from '../images/icons/class/pgl.inline.svg';
import Lnc from '../images/icons/class/lnc.inline.svg';
import Rog from '../images/icons/class/rog.inline.svg';

import Arc from '../images/icons/class/arc.inline.svg';

import Thm from '../images/icons/class/thm.inline.svg';

// craf
import Crp from '../images/icons/crafter/crp.inline.svg';
import Bsm from '../images/icons/crafter/bsm.inline.svg';
import Arm from '../images/icons/crafter/arm.inline.svg';
import Gsm from '../images/icons/crafter/gsm.inline.svg';

import Ltw from '../images/icons/crafter/ltw.inline.svg';
import Wvr from '../images/icons/crafter/wvr.inline.svg';
import Alc from '../images/icons/crafter/alc.inline.svg';
import Cul from '../images/icons/crafter/cul.inline.svg';

// gath
import Min from '../images/icons/gatherer/min.inline.svg';
import Btn from '../images/icons/gatherer/btn.inline.svg';
import Fsh from '../images/icons/gatherer/fsh.inline.svg';

export const JobIcons = {
  pld: Pld,
  war: War,
  drk: Drk,
  gnb: Gnb,
  whm: Whm,
  sch: Sch,
  ast: Ast,
  sge: Sge,
  mnk: Mnk,
  drg: Drg,
  nin: Nin,
  sam: Sam,
  rpr: Rpr,
  brd: Brd,
  mch: Mch,
  dnc: Dnc,
  blm: Blm,
  smn: Smn,
  rdm: Rdm,
  blu: Blu
}

export const ClassIcons = {
  gla: Gla,
  mrd: Mrd,
  cnj: Cnj,
  acn: Acn,
  pgl: Pgl,
  lnc: Lnc,
  rog: Rog,
  arc: Arc,
  thm: Thm
}

export const CrafterIcons = {
  crp: Crp,
  bsm: Bsm,
  arm: Arm,
  gsm: Gsm,
  ltw: Ltw,
  wvr: Wvr,
  alc: Alc,
  cul: Cul
}

export const GathererIcons = {
  min: Min,
  btn: Btn,
  fsh: Fsh,
}

export const JobIcon = (props) => {
  const Icon = JobIcons[props.slug] || ClassIcons[props.slug] || CrafterIcons[props.slug] || GathererIcons[props.slug];

  return (<Icon {...props} />);
}

export const JobBadge = (props) => {
  const attrProps = {...props};

  delete attrProps.slug;
  delete attrProps.label;

  return (
    <span css={JobBadgeStyle} {...attrProps}>
      <JobIcon className="badge-icon" slug={props.slug} />
      { props.label && <span className="badge-label">{props.label}</span>}
    </span>
  );
}

const JobBadgeStyle = css`
  padding: .25rem;
  min-width: 1rem;

  white-space: nowrap;
  font-size: .825rem;
  line-height: 1rem;
  color: rgba(255,255,255,1);

  border-radius: calc(1.5rem / 2);

  .badge-icon {
    display: inline-block;
    width: 1rem;
    height: 1rem;

    vertical-align: bottom;
  }

  .badge-label {
    display: none;
    margin-left: .35em;
    margin-right: .25em;
    font-weight: bold;
  }
  &:hover .badge-label {
    display: inline-block;
  }

  &[data-job-type="tank"] {
    background: #3F51B5;
  }

  &[data-job-type="healer"] {
    background: #7CB342;
  }

  &[data-job-type="melee"] {
    background: #B71C1C;
  }

  &[data-job-type="range"] {
    background: #B71C1C;
  }

  &[data-job-type="magic"] {
    background: #B71C1C;
  }

  &[data-job-type="crafter"] {
    background: #6D6F70;
  }

  &[data-job-type="gatherer"] {
    background: #6D6F70;
  }
`;
